<template>
  <div style="background-color:#F04138;min-height: 100vh;overflow:hidden;">
    <div class="topBg display_flex" :style="{ backgroundImage: 'url(img/topBg.png)' }">
      <back-nav class="title_top" :icon="'img/backw.png'" :textColor="'#fff'" :backColor="'none'" />
    </div>
    <div class="index_code" style="background-image: url('img/index_code.png');">
      <!-- <div class="display_flex align-items_center" style="justify-content: space-around;margin-top: 15px;"> -->
        <div class="invite_code" style="background-image: url('img/invite_code.png');">
          <div style="font-size: 14px;color: #fff;font-weight: 700;">邀请码：{{ indexData.invite_code }}</div>
          <button class="tag-read index_btn margin-left" style="background-image: url('img/index_copy.png');"
            :data-clipboard-text="saveData.introduce_code" @click="copy"></button>
        </div>
        <!-- <div style="display:flex;align-items: center;"> -->
          <!-- <div>{{ saveData.introduce_code }}</div> -->
        <!-- </div> -->


      <!-- </div> -->
      <div style="display: flex;justify-content: space-between;margin-top: 62px;">
        <div style="flex: 1;text-align: center;">
          <div style="font-size: 28px;font-weight: 700;color: #ff5943;">{{indexData.total_money}}</div>
          <div style="font-size: 16px;">累计奖励</div>
        </div>
        <div style="flex: 1;text-align: center;">
          <div style="font-size: 28px;font-weight: 700;color: #ff5943;">{{indexData.current_money}}</div>
          <div style="font-size: 16px;">剩余奖励</div>
        </div>
      </div>
      <div class="value_box">
      <div class="value_item" style="background-image: url('img/juxing.png');">
        <div class="value">{{indexData.invite_num}}</div>
        <div class="value_title">邀请人数(人)</div>
      </div>
      <div class="value_item" style="background-image: url('img/juxing.png');">
        <div class="value">{{indexData.total_send_money}}</div>
        <div class="value_title">累计赠送礼物(元)</div>
      </div>
      <div class="value_item" style="background-image: url('img/juxing.png');">
        <div class="value">{{indexData.total_recharge}}</div>
        <div class="value_title">累计充值(元)</div>
      </div>
    </div>
      <div style="background-image: url('img/invite_firend.png');" class="invite_btn" @click="goSave"></div>
    </div>
    <div class="tab_box">
      <div style="background-image: url('img/my_record.png');" class="list_tab">
        <router-link style="width:100%;height: 100%;display: block;" :to="'/myInvite?invite_num=' + indexData.invite_num + '&send_money=' + indexData.total_send_money+ '&effective_num=' + indexData.total_recharge"></router-link>
      </div>
      <!-- <div style="background-image: url(img/my_money.png);" class="money_tab">
        <router-link style="width:100%;height: 100%;display: block;" :to="'/withDraw?current_Money=' + indexData.current_money"></router-link>
      </div> -->
    </div>
    <div class="top_rule" @click="goRule">
      <img src="img/question.png" alt="">
    </div>

    <wechat-share @childFn="parentFn" @saveData="getSaveData" />

  </div>
</template>

<script>
import Clipboard from "clipboard";
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { inviteInfo,getCode } from "@/api/api";
import WechatShare from '@/components/wechatShare.vue';
import BackNav from '@/components/backNav.vue';
export default {
  name: 'index',
  components: {
    WechatShare,
    BackNav
  },
  data() {
    return {
      indexData: {},
      beforeUrl: '',
      img: '',
      saveData: {}
    };
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.fetchData()
    this.getInfo()
  },
  methods: {
    getInfo(){
      getCode({}).then(res=>{})
    },
    fetchData() {
      inviteInfo().then(response => {
        
        this.indexData = response.data
        console.log(this.indexData)
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },
    parentFn(url) {
      this.img = url;
    },
    getSaveData(data) {
      this.saveData = data
    },
    goSave() {
      if (JSON.stringify(this.saveData) == "{}") {
        return false
      }
      var img = this.img
      let str = {
        url: img
      }
      let param = JSON.stringify(str)
      this.$bridge.callhandler('doShareTask', param, (data) => {
        // 处理返回数据
      })
    },
    goRule() {
      this.$router.push({ path: '/rule' })
    },
    copy() {
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$toast('已复制到粘贴板')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        Toast('该浏览器不支持自动复制,请手动输入')
        // 释放内存
        clipboard.destroy()
      })
    },

  },
  watch: {
    '$route': "fetchData"
  }
};
</script>
